var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c("ck-loader")
    : _c(
        "div",
        [
          _c(
            "gov-form-group",
            [
              _c("gov-label", { attrs: { for: "filter[name]" } }, [
                _vm._v("Search by taxonomy name")
              ]),
              _c("gov-input", {
                attrs: {
                  id: "filter[name]",
                  name: "filter[name]",
                  type: "search"
                },
                model: {
                  value: _vm.filters.name,
                  callback: function($$v) {
                    _vm.$set(_vm.filters, "name", $$v)
                  },
                  expression: "filters.name"
                }
              })
            ],
            1
          ),
          _c(
            "gov-button",
            {
              on: {
                click: function($event) {
                  _vm.filters.name = ""
                }
              }
            },
            [_vm._v("Clear")]
          ),
          _vm.filteredTaxonomyIds.length
            ? _c(
                "gov-form-group",
                [
                  _c("ck-taxonomy-tree", {
                    attrs: {
                      taxonomies: _vm.taxonomies,
                      checked: _vm.value,
                      filteredTaxonomyIds: _vm.filteredTaxonomyIds,
                      taxonomyCollections: _vm.taxonomyCollections,
                      error: _vm.error,
                      disabled: _vm.disabled
                    },
                    on: { update: _vm.onUpdate }
                  }),
                  _vm.error
                    ? _c("gov-error-message", {
                        attrs: { for: "category_taxonomies" },
                        domProps: { textContent: _vm._s(_vm.error) }
                      })
                    : _vm._e()
                ],
                1
              )
            : _c("gov-inset-text", [_vm._v(" No matching taxonomies found ")])
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }